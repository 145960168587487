import React, { useEffect, useState, useCallback } from 'react'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'

import { StarIcon } from '@heroicons/react/solid'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'

const slides = [
  {
    review: `I'm so happy with the work ProGro have done and wouldn't hesitate to use them again and again. They were polite and professional at all times. I've recommended them to all my friends and family.`,
    author: `Joy, Ascot`,
  },
  {
    review: `Such Professionalism and great quality service. Super flexible, cost effective, accommodating and fabulous result. Can't recommend enough. Happy customer!`,
    author: `Lara, Reading`,
  },
  {
    review: `I highly recommend Jake and Bradley... did a fabulous job of re-turfing my garden. They have totally transformed it and I will be contacting them again to landscape the rest of my garden in the next couple of months. Thanks again`,
    author: `Lynne, Warfield`,
  },
  {
    review: `We have just had a fabulous back fence erected by these two great lads. Can't recommend them highly enough. One time clean job, very pleased, will definitely use again.`,
    author: `Gary, Ascot`,
  },
  {
    review: `The boys have done a great job on my new patio. I would gladly recommend them to anyone. GREAT JOB GUYS`,
    author: `Les, Ascot`,
  },
  {
    review: `If you are looking for honesty, trustworthy and reliable, then look no further. Just ask to give you a quote and see what I mean.\nThank you Jake and Brad for all your great work`,
    author: `Michael, Yately`,
  },
]

function Testimonials() {
  const [slide, setSlide] = useState(0)
  const [hovering, setHovering] = useState(false)

  const next = useCallback(
    () => setSlide((num) => (num >= slides.length - 1 ? 0 : num + 1)),
    [setSlide]
  )

  const prev = useCallback(
    () => setSlide((num) => (num > 0 ? num - 1 : 0)),
    [setSlide]
  )

  useEffect(() => {
    const progress = setTimeout(() => (hovering ? null : next()), 5000)
    return () => clearTimeout(progress)
  }, [slide, hovering, next])

  const handleHover = useCallback(() => setHovering(true), [setHovering])
  const handleBlur = useCallback(() => setHovering(false), [setHovering])

  const handleSeek = useCallback(
    (event) => setSlide(parseInt(event.target.value, 10)),
    [setSlide]
  )

  return (
    <section
      className="py-12 text-white bg-gray overflow-hidden md:py-20 lg:py-24"
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Transition
          key={slides[slide].author}
          as="figure"
          className="h-96 lg:h-64"
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          appear
          show
        >
          <aside className="flex justify-center text-green">
            <StarIcon className="w-8 h-8" />
            <StarIcon className="w-8 h-8" />
            <StarIcon className="w-8 h-8" />
            <StarIcon className="w-8 h-8" />
            <StarIcon className="w-8 h-8" />
          </aside>
          <blockquote className="mt-8">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium">
              <p>&ldquo;{slides[slide].review}&rdquo;</p>
            </div>
          </blockquote>
          <figcaption className="mt-8 flex items-center justify-center">
            <p>— {slides[slide].author}</p>
          </figcaption>
        </Transition>

        <div className="flex justify-center mt-8">
          <button
            type="button"
            className="mx-4 disabled:invisible"
            onClick={prev}
            disabled={slide <= 0}
            aria-label="Previous slide"
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          {slides.map(({ author }, i) => (
            <button
              key={author}
              type="button"
              value={i}
              className={clsx(
                'mx-4 text-3xl focus:outline-none',
                slide === i && 'text-green'
              )}
              onClick={handleSeek}
              aria-label={`Go to slide ${i + 1}`}
            >
              •
            </button>
          ))}
          <button
            type="button"
            className="mx-4 disabled:invisible"
            onClick={next}
            disabled={slide >= slides.length - 1}
            aria-label="Next slide"
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
