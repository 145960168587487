import React from 'react'

function Mission() {
  return (
    <div className="bg-gray text-white pt-12 sm:pt-16">
      <div className="pb-12 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 text-green-light">
                    Based predominantly in Ascot, Berkshire. ProGro Landscapes
                    offers landscaping services to Berkshire and the surrounding
                    areas.
                  </dt>
                  <dd className="order-1 text-2xl uppercase font-bold">
                    Local
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 text-green-light">
                    We work hard to make beautiful landscapes. Our focus is our
                    clients, and we aim to give them the quality they deserve.
                    We build gardens that last.
                  </dt>
                  <dd className="order-1 text-2xl uppercase font-bold">
                    Quality
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 text-green-light">
                    We have a consistent history of great reviews. We have a 5
                    star rating on both Google and Facebook
                  </dt>
                  <dd className="order-1 text-2xl uppercase font-bold">
                    Trusted
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission
