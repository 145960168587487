import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/outline'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Mission from '../components/Mission'
import Testimonials from '../components/Testimonials'
import Features from '../components/Features'

function Home({ location }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneDisplay
        }
      }
    }
  `)

  const { phone, phoneDisplay } = site.siteMetadata

  return (
    <>
      <Meta
        title="Professional Landscape Gardeners in Berkshire"
        description="Based predominantly in Ascot, Berkshire. ProGro Landscapes offers landscaping services to Berkshire and the surrounding areas. Get in touch today to arrange a free quote."
        location={location}
      />
      <Layout backgroundImage>
        <Mission />
        <section className="bg-white text-gray">
          <div className="max-w-2xl mx-auto text-center py-16 px-4 space-y-8 sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold sm:text-4xl">
              Professional Landscape Gardeners in Berkshire
            </h2>
            <div className="space-y-4">
              <h3 className="text-xl font-extrabold sm:text-2xl">
                Which areas do we cover?
              </h3>
              <p className="text-lg leading-6">
                Based in Ascot, we are perfectly situated to cover Berkshire and
                Surrey.
              </p>
              <p className="text-lg leading-6">
                The majority of our clients are based in Windsor, Maidenhead,
                Ascot, Virginia Water, Warfield, Bracknell, Wokingham, and
                surrounding areas.
              </p>
            </div>
            <a
              href={`tel:${phone}`}
              className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base rounded-md text-white bg-green hover:bg-green-light sm:w-auto"
            >
              <PhoneIcon className="w-4 h-4 mr-1" />
              <span>{phoneDisplay}</span>
            </a>
          </div>
        </section>
        <section className="relative bg-white pb-20 px-4">
          <div className="relative max-w-7xl mx-auto">
            <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage
                    src="../images/services/fencing-thumb-1.webp"
                    alt="Fencing"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
                <div className="flex-1 p-6">
                  <Link to="/fencing/" className="block">
                    <p className="text-xl font-semibold text-gray">Fencing</p>
                    <p className="mt-2 text-base text-gray-light">
                      Discover our fencing services and see how we can help you.
                    </p>
                    <span className="inline-block mt-4 lg:float-right px-5 py-3 border border-transparent rounded-md text-white bg-green hover:bg-green-light sm:w-auto">
                      View our work
                    </span>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage
                    src="../images/services/patio-decking-thumb-1.webp"
                    alt="Patio & Decking"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
                <div className="flex-1 p-6">
                  <Link to="/patio-and-decking/" className="block">
                    <p className="text-xl font-semibold text-gray">
                      Patio & Decking
                    </p>
                    <p className="mt-2 text-base text-gray-light">
                      Beautiful and strong. See more of our patio and decking
                      work.
                    </p>
                    <span className="inline-block mt-4 lg:float-right px-5 py-3 border border-transparent rounded-md text-white bg-green hover:bg-green-light sm:w-auto">
                      View our work
                    </span>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <StaticImage
                    src="../images/services/lawn-thumb-1.webp"
                    alt="Lawn Installation"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
                <div className="flex-1 p-6">
                  <Link to="/lawn-installation/" className="block">
                    <p className="text-xl font-semibold text-gray">
                      Lawn Installation
                    </p>
                    <p className="mt-2 text-base text-gray-light">
                      Expert advice on reinstating or caring for your lawn.
                      Turfing and lawn seeding.
                    </p>
                    <span className="inline-block mt-4 lg:float-right px-5 py-3 border border-transparent rounded-md text-white bg-green hover:bg-green-light sm:w-auto">
                      View our work
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonials />
        <Features
          list={[
            {
              name: 'Lawn Installation',
              description:
                'The grass is greener with our lawn installation services.',
            },
            {
              name: 'Planting',
              description:
                'Tree and shrub planting, and planting scheme designs.',
            },
            {
              name: 'Patio & Decking',
              description:
                'Bespoke hard landscaping to create beautiful landscapes.',
            },
            {
              name: 'Hedge Cutting',
              description: 'Reduction, removal and trimming of hedges.',
            },
            {
              name: 'Fencing',
              description:
                'All types of fencing undertaken with competitive rates.',
            },
            {
              name: 'Grounds Maintenance',
              description:
                'Maintaining beautiful landscapes under our thorough maintenance regime.',
            },
          ]}
        />
      </Layout>
    </>
  )
}

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Home
