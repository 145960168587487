import React from 'react'
import PropTypes from 'prop-types'

import { LightBulbIcon } from '@heroicons/react/solid'

function Features({ list }) {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {list.map(({ name, description }) => (
            <div key={name} className="pt-6">
              <div className="flow-root bg-white shadow rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-1 bg-yellow rounded-md shadow-lg">
                      <LightBulbIcon
                        className="h-12 w-12 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg uppercase">{name}</h3>
                  <p className="mt-5 text-base text-gray-light">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Features.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
}

export default Features
